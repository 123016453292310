import * as React from 'react';
import { composeEventHandlers } from '@radix-ui/primitive';
import { useComposedRefs } from '@radix-ui/react-compose-refs';
import { createContextScope } from '@radix-ui/react-context';
import { DismissableLayer } from '@radix-ui/react-dismissable-layer';
import { useFocusGuards } from '@radix-ui/react-focus-guards';
import { FocusScope } from '@radix-ui/react-focus-scope';
import { useId } from '@radix-ui/react-id';
import * as PopperPrimitive from '@radix-ui/react-popper';
import { createPopperScope } from '@radix-ui/react-popper';
import { Portal as PortalPrimitive } from '@radix-ui/react-portal';
import { Presence } from '@radix-ui/react-presence';
import { Primitive } from '@radix-ui/react-primitive';
import { Slot } from '@radix-ui/react-slot';
import { useControllableState } from '@radix-ui/react-use-controllable-state';
import { hideOthers } from 'aria-hidden';
import { RemoveScroll } from 'react-remove-scroll';

import type { Scope } from '@radix-ui/react-context';

/* -------------------------------------------------------------------------------------------------
 * Popover
 * -----------------------------------------------------------------------------------------------*/

const POPOVER_NAME = 'Popover';

type ScopedProps<p> = P & { __scopePopover?: Scope };
const [createPopoverContext, createPopoverScope] = createContextScope(POPOVER_NAME, [
  createPopperScope,
]);
const usePopperScope = createPopperScope();

type PopoverContextValue = {
  triggerRef: React.RefObject<htmlbuttonelement>;
  contentId: string;
  open: boolean;
  onOpenChange(open: boolean): void;
  onOpenToggle(): void;
  hasCustomAnchor: boolean;
  onCustomAnchorAdd(): void;
  onCustomAnchorRemove(): void;
  modal: boolean;
};

const [PopoverProvider, usePopoverContext] =
  createPopoverContext<popovercontextvalue>(POPOVER_NAME);

interface PopoverProps {
  children?: React.ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  modal?: boolean;
}

const Popover: React.FC<popoverprops> = (props: ScopedProps<popoverprops>) => {
  const {
    __scopePopover,
    children,
    open: openProp,
    defaultOpen,
    onOpenChange,
    modal = false,
  } = props;
  const popperScope = usePopperScope(__scopePopover);
  const triggerRef = React.useRef<htmlbuttonelement>(null);
  const [hasCustomAnchor, setHasCustomAnchor] = React.useState(false);
  const [open = false, setOpen] = useControllableState({
    prop: openProp,
    defaultProp: defaultOpen,
    onChange: onOpenChange,
  });

  return (
    <popperprimitive.root {...popperScope}="">
      <popoverprovider scope="{__scopePopover}" contentId="{useId()}" triggerRef="{triggerRef}" open="{open}" onOpenChange="{setOpen}" onOpenToggle="{React.useCallback(()" ==""> setOpen((prevOpen) => !prevOpen), [setOpen])}
        hasCustomAnchor={hasCustomAnchor}
        onCustomAnchorAdd={React.useCallback(() => setHasCustomAnchor(true), [])}
        onCustomAnchorRemove={React.useCallback(() => setHasCustomAnchor(false), [])}
        modal={modal}
      >
        {children}
      </popoverprovider>
    </popperprimitive.root>
  );
};

Popover.displayName = POPOVER_NAME;

/* -------------------------------------------------------------------------------------------------
 * PopoverAnchor
 * -----------------------------------------------------------------------------------------------*/

const ANCHOR_NAME = 'PopoverAnchor';

type PopoverAnchorElement = React.ElementRef<typeof PopperPrimitive.Anchor="">;
type PopperAnchorProps = React.ComponentPropsWithoutRef<typeof PopperPrimitive.Anchor="">;
interface PopoverAnchorProps extends PopperAnchorProps {}

const PopoverAnchor = React.forwardRef<popoveranchorelement, PopoverAnchorProps="">(
  (props: ScopedProps<popoveranchorprops>, forwardedRef) => {
    const { __scopePopover, ...anchorProps } = props;
    const context = usePopoverContext(ANCHOR_NAME, __scopePopover);
    const popperScope = usePopperScope(__scopePopover);
    const { onCustomAnchorAdd, onCustomAnchorRemove } = context;

    React.useEffect(() => {
      onCustomAnchorAdd();
      return () => onCustomAnchorRemove();
    }, [onCustomAnchorAdd, onCustomAnchorRemove]);

    return <popperprimitive.anchor {...popperScope}="" {...anchorProps}="" ref="{forwardedRef}"></popperprimitive.anchor>;
  }
);

PopoverAnchor.displayName = ANCHOR_NAME;

/* -------------------------------------------------------------------------------------------------
 * PopoverTrigger
 * -----------------------------------------------------------------------------------------------*/

const TRIGGER_NAME = 'PopoverTrigger';

type PopoverTriggerElement = React.ElementRef<typeof Primitive.button="">;
type PrimitiveButtonProps = React.ComponentPropsWithoutRef<typeof Primitive.button="">;
interface PopoverTriggerProps extends PrimitiveButtonProps {}

const PopoverTrigger = React.forwardRef<popovertriggerelement, PopoverTriggerProps="">(
  (props: ScopedProps<popovertriggerprops>, forwardedRef) => {
    const { __scopePopover, ...triggerProps } = props;
    const context = usePopoverContext(TRIGGER_NAME, __scopePopover);
    const popperScope = usePopperScope(__scopePopover);
    const composedTriggerRef = useComposedRefs(forwardedRef, context.triggerRef);

    const trigger = (
      <primitive.button type="button" aria-haspopup="dialog" aria-expanded="{context.open}" aria-controls="{context.contentId}" data-state="{getState(context.open)}" {...triggerProps}="" ref="{composedTriggerRef}" onClick="{composeEventHandlers(props.onClick," context.onOpenToggle)}=""></primitive.button>
    );

    return context.hasCustomAnchor ? (
      trigger
    ) : (
      <popperprimitive.anchor asChild="" {...popperScope}="">
        {trigger}
      </popperprimitive.anchor>
    );
  }
);

PopoverTrigger.displayName = TRIGGER_NAME;

/* -------------------------------------------------------------------------------------------------
 * PopoverPortal
 * -----------------------------------------------------------------------------------------------*/

const PORTAL_NAME = 'PopoverPortal';

type PortalContextValue = { forceMount?: true };
const [PortalProvider, usePortalContext] = createPopoverContext<portalcontextvalue>(PORTAL_NAME, {
  forceMount: undefined,
});

type PortalProps = React.ComponentPropsWithoutRef<typeof PortalPrimitive="">;
interface PopoverPortalProps {
  children?: React.ReactNode;
  /**
   * Specify a container element to portal the content into.
   */
  container?: PortalProps['container'];
  /**
   * Used to force mounting when more control is needed. Useful when
   * controlling animation with React animation libraries.
   */
  forceMount?: true;
}

const PopoverPortal: React.FC<popoverportalprops> = (props: ScopedProps<popoverportalprops>) => {
  const { __scopePopover, forceMount, children, container } = props;
  const context = usePopoverContext(PORTAL_NAME, __scopePopover);
  return (
    <portalprovider scope="{__scopePopover}" forceMount="{forceMount}">
      <presence present="{forceMount" ||="" context.open}="">
        <portalprimitive asChild="" container="{container}">
          {children}
        </portalprimitive>
      </presence>
    </portalprovider>
  );
};

PopoverPortal.displayName = PORTAL_NAME;

/* -------------------------------------------------------------------------------------------------
 * PopoverContent
 * -----------------------------------------------------------------------------------------------*/

const CONTENT_NAME = 'PopoverContent';

interface PopoverContentProps extends PopoverContentTypeProps {
  /**
   * Used to force mounting when more control is needed. Useful when
   * controlling animation with React animation libraries.
   */
  forceMount?: true;
}

const PopoverContent = React.forwardRef<popovercontenttypeelement, PopoverContentProps="">(
  (props: ScopedProps<popovercontentprops>, forwardedRef) => {
    const portalContext = usePortalContext(CONTENT_NAME, props.__scopePopover);
    const { forceMount = portalContext.forceMount, ...contentProps } = props;
    const context = usePopoverContext(CONTENT_NAME, props.__scopePopover);
    return (
      <presence present="{forceMount" ||="" context.open}="">
        {context.modal ? (
          <popovercontentmodal {...contentProps}="" ref="{forwardedRef}"></popovercontentmodal>
        ) : (
          <popovercontentnonmodal {...contentProps}="" ref="{forwardedRef}"></popovercontentnonmodal>
        )}
      </presence>
    );
  }
);

PopoverContent.displayName = CONTENT_NAME;

/* -----------------------------------------------------------------------------------------------*/

type PopoverContentTypeElement = PopoverContentImplElement;
interface PopoverContentTypeProps
  extends Omit<popovercontentimplprops, 'trapFocus'="" |="" 'disableOutsidePointerEvents'=""> {}

const PopoverContentModal = React.forwardRef<popovercontenttypeelement, PopoverContentTypeProps="">(
  (props: ScopedProps<popovercontenttypeprops>, forwardedRef) => {
    const context = usePopoverContext(CONTENT_NAME, props.__scopePopover);
    const contentRef = React.useRef<htmldivelement>(null);
    const composedRefs = useComposedRefs(forwardedRef, contentRef);
    const isRightClickOutsideRef = React.useRef(false);

    // aria-hide everything except the content (better supported equivalent to setting aria-modal)
    React.useEffect(() => {
      const content = contentRef.current;
      if (content) return hideOthers(content);
    }, []);

    return (
      <removescroll as="{Slot}" allowPinchZoom="">
        <popovercontentimpl {...props}="" ref="{composedRefs}" we="" make="" sure="" we're="" not="" trapping="" once="" it's="" been="" closed="" (closed="" !="=" unmounted="" when="" animating="" out)="" trapFocus="{context.open}" disableOutsidePointerEvents="" onCloseAutoFocus="{composeEventHandlers(props.onCloseAutoFocus," (event)=""> {
            event.preventDefault();
            if (!isRightClickOutsideRef.current) context.triggerRef.current?.focus();
          })}
          onPointerDownOutside={composeEventHandlers(
            props.onPointerDownOutside,
            (event) => {
              const originalEvent = event.detail.originalEvent;
              const ctrlLeftClick = originalEvent.button === 0 && originalEvent.ctrlKey === true;
              const isRightClick = originalEvent.button === 2 || ctrlLeftClick;

              isRightClickOutsideRef.current = isRightClick;
            },
            { checkForDefaultPrevented: false }
          )}
          // When focus is trapped, a `focusout` event may still happen.
          // We make sure we don't trigger our `onDismiss` in such case.
          onFocusOutside={composeEventHandlers(
            props.onFocusOutside,
            (event) => event.preventDefault(),
            { checkForDefaultPrevented: false }
          )}
        />
      </popovercontentimpl></removescroll>
    );
  }
);

const PopoverContentNonModal = React.forwardRef<popovercontenttypeelement, PopoverContentTypeProps="">(
  (props: ScopedProps<popovercontenttypeprops>, forwardedRef) => {
    const context = usePopoverContext(CONTENT_NAME, props.__scopePopover);
    const hasInteractedOutsideRef = React.useRef(false);
    const hasPointerDownOutsideRef = React.useRef(false);

    return (
      <popovercontentimpl {...props}="" ref="{forwardedRef}" trapFocus="{false}" disableOutsidePointerEvents="{false}" onCloseAutoFocus="{(event)" ==""> {
          props.onCloseAutoFocus?.(event);

          if (!event.defaultPrevented) {
            if (!hasInteractedOutsideRef.current) context.triggerRef.current?.focus();
            // Always prevent auto focus because we either focus manually or want user agent focus
            event.preventDefault();
          }

          hasInteractedOutsideRef.current = false;
          hasPointerDownOutsideRef.current = false;
        }}
        onInteractOutside={(event) => {
          props.onInteractOutside?.(event);

          if (!event.defaultPrevented) {
            hasInteractedOutsideRef.current = true;
            if (event.detail.originalEvent.type === 'pointerdown') {
              hasPointerDownOutsideRef.current = true;
            }
          }

          // Prevent dismissing when clicking the trigger.
          // As the trigger is already setup to close, without doing so would
          // cause it to close and immediately open.
          const target = event.target as HTMLElement;
          const targetIsTrigger = context.triggerRef.current?.contains(target);
          if (targetIsTrigger) event.preventDefault();

          // On Safari if the trigger is inside a container with tabIndex={0}, when clicked
          // we will get the pointer down outside event on the trigger, but then a subsequent
          // focus outside event on the container, we ignore any focus outside event when we've
          // already had a pointer down outside event.
          if (event.detail.originalEvent.type === 'focusin' && hasPointerDownOutsideRef.current) {
            event.preventDefault();
          }
        }}
      />
    );
  }
);

/* -----------------------------------------------------------------------------------------------*/

type PopoverContentImplElement = React.ElementRef<typeof PopperPrimitive.Content="">;
type FocusScopeProps = React.ComponentPropsWithoutRef<typeof FocusScope="">;
type DismissableLayerProps = React.ComponentPropsWithoutRef<typeof DismissableLayer="">;
type PopperContentProps = React.ComponentPropsWithoutRef<typeof PopperPrimitive.Content="">;
interface PopoverContentImplProps
  extends Omit<poppercontentprops, 'onPlaced'="">,
    Omit<dismissablelayerprops, 'onDismiss'=""> {
  /**
   * Whether focus should be trapped within the `Popover`
   * (default: false)
   */
  trapFocus?: FocusScopeProps['trapped'];

  /**
   * Event handler called when auto-focusing on open.
   * Can be prevented.
   */
  onOpenAutoFocus?: FocusScopeProps['onMountAutoFocus'];

  /**
   * Event handler called when auto-focusing on close.
   * Can be prevented.
   */
  onCloseAutoFocus?: FocusScopeProps['onUnmountAutoFocus'];
}

const PopoverContentImpl = React.forwardRef<popovercontentimplelement, PopoverContentImplProps="">(
  (props: ScopedProps<popovercontentimplprops>, forwardedRef) => {
    const {
      __scopePopover,
      trapFocus,
      onOpenAutoFocus,
      onCloseAutoFocus,
      disableOutsidePointerEvents,
      onEscapeKeyDown,
      onPointerDownOutside,
      onFocusOutside,
      onInteractOutside,
      ...contentProps
    } = props;
    const context = usePopoverContext(CONTENT_NAME, __scopePopover);
    const popperScope = usePopperScope(__scopePopover);

    // Make sure the whole tree has focus guards as our `Popover` may be
    // the last element in the DOM (because of the `Portal`)
    useFocusGuards();

    return (
      <focusscope asChild="" loop="" trapped="{trapFocus}" onMountAutoFocus="{onOpenAutoFocus}" onUnmountAutoFocus="{onCloseAutoFocus}">
        <dismissablelayer asChild="" disableOutsidePointerEvents="{disableOutsidePointerEvents}" onInteractOutside="{onInteractOutside}" onEscapeKeyDown="{onEscapeKeyDown}" onPointerDownOutside="{onPointerDownOutside}" onFocusOutside="{onFocusOutside}" onDismiss="{()" ==""> context.onOpenChange(false)}
        >
          <popperprimitive.content data-state="{getState(context.open)}" role="dialog" id="{context.contentId}" {...popperScope}="" {...contentProps}="" ref="{forwardedRef}" style="{{" ...contentProps.style,="" re-namespace="" exposed="" content="" custom="" properties="" ...{="" '--radix-popover-content-transform-origin':="" 'var(--radix-popper-transform-origin)',="" '--radix-popover-content-available-width':="" 'var(--radix-popper-available-width)',="" '--radix-popover-content-available-height':="" 'var(--radix-popper-available-height)',="" '--radix-popover-trigger-width':="" 'var(--radix-popper-anchor-width)',="" '--radix-popover-trigger-height':="" 'var(--radix-popper-anchor-height)',="" },="" }}=""></popperprimitive.content>
        </dismissablelayer>
      </focusscope>
    );
  }
);

/* -------------------------------------------------------------------------------------------------
 * PopoverClose
 * -----------------------------------------------------------------------------------------------*/

const CLOSE_NAME = 'PopoverClose';

type PopoverCloseElement = React.ElementRef<typeof Primitive.button="">;
interface PopoverCloseProps extends PrimitiveButtonProps {}

const PopoverClose = React.forwardRef<popovercloseelement, PopoverCloseProps="">(
  (props: ScopedProps<popovercloseprops>, forwardedRef) => {
    const { __scopePopover, ...closeProps } = props;
    const context = usePopoverContext(CLOSE_NAME, __scopePopover);
    return (
      <primitive.button type="button" {...closeProps}="" ref="{forwardedRef}" onClick="{composeEventHandlers(props.onClick," ()=""> context.onOpenChange(false))}
      />
    );
  }
);

PopoverClose.displayName = CLOSE_NAME;

/* -------------------------------------------------------------------------------------------------
 * PopoverArrow
 * -----------------------------------------------------------------------------------------------*/

const ARROW_NAME = 'PopoverArrow';

type PopoverArrowElement = React.ElementRef<typeof PopperPrimitive.Arrow="">;
type PopperArrowProps = React.ComponentPropsWithoutRef<typeof PopperPrimitive.Arrow="">;
interface PopoverArrowProps extends PopperArrowProps {}

const PopoverArrow = React.forwardRef<popoverarrowelement, PopoverArrowProps="">(
  (props: ScopedProps<popoverarrowprops>, forwardedRef) => {
    const { __scopePopover, ...arrowProps } = props;
    const popperScope = usePopperScope(__scopePopover);
    return <popperprimitive.arrow {...popperScope}="" {...arrowProps}="" ref="{forwardedRef}"></popperprimitive.arrow>;
  }
);

PopoverArrow.displayName = ARROW_NAME;

/* -----------------------------------------------------------------------------------------------*/

function getState(open: boolean) {
  return open ? 'open' : 'closed';
}

const Root = Popover;
const Anchor = PopoverAnchor;
const Trigger = PopoverTrigger;
const Portal = PopoverPortal;
const Content = PopoverContent;
const Close = PopoverClose;
const Arrow = PopoverArrow;

export {
  createPopoverScope,
  //
  Popover,
  PopoverAnchor,
  PopoverTrigger,
  PopoverPortal,
  PopoverContent,
  PopoverClose,
  PopoverArrow,
  //
  Root,
  Anchor,
  Trigger,
  Portal,
  Content,
  Close,
  Arrow,
};
export type {
  PopoverProps,
  PopoverAnchorProps,
  PopoverTriggerProps,
  PopoverPortalProps,
  PopoverContentProps,
  PopoverCloseProps,
  PopoverArrowProps,
};
</popoverarrowprops></popoverarrowelement,></typeof></typeof></primitive.button></popovercloseprops></popovercloseelement,></typeof></popovercontentimplprops></popovercontentimplelement,></dismissablelayerprops,></poppercontentprops,></typeof></typeof></typeof></typeof></popovercontentimpl></popovercontenttypeprops></popovercontenttypeelement,></htmldivelement></popovercontenttypeprops></popovercontenttypeelement,></popovercontentimplprops,></popovercontentprops></popovercontenttypeelement,></popoverportalprops></popoverportalprops></typeof></portalcontextvalue></popovertriggerprops></popovertriggerelement,></typeof></typeof></popoveranchorprops></popoveranchorelement,></typeof></typeof></htmlbuttonelement></popoverprops></popoverprops></popovercontextvalue></htmlbuttonelement></p>